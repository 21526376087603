<template>
<div class="trips-container">
    <div class="header">
        <h2 style="margin: 0;">{{ $t('trips.title') }}</h2>
        <p style="margin: 0;">{{ $t('trips.caption') }}</p>
    </div>
    <div style="width: 100%; display: flex; justify-content: flex-end; margin-top: -20px; z-index: 2000;">
        <vs-button size="large" circle style="height: 50px;" @click="newTripEvent"><img src="../../assets/icons/add.png" style="width: 20px;" /></vs-button>
    </div>
    <div class="trips" v-if="trips != null && trips.length > 0">
        <Trip v-for="trip in trips" :key="'trip-'+trip.id" :trip="trip" @click="$router.push('/explore/trips/' + trip.id)" @delete="deleteTrip" />
    </div>
    <div v-else style="display: flex; flex-direction: column; gap: 20px; align-items: center;">
        <img src="../../assets/images/no_trips.png" style="width: 50%;" />
        <h3>{{ $t('trips.noTrips') }}</h3>
    </div>
</div>
</template>

<script>
import Trip from '../Trip.vue';
import NewTripDialog from '../dialogs/NewTripDialog.vue';
import {
    GlobalEventEmitter
} from '@/utils/GlobalEventEmitter'
import {
    apiCall
} from '@/utils/ApiMiddleware.js';
import TrialExpiredDialog from '../dialogs/TrialExpiredDialog.vue';

export default {
    name: "trips",
    props: {},
    components: {
        Trip
    },

    data() {
        return {
            trips: [],

            result: null,
            after: null
        }
    },

    async mounted() {
        const loading = this.$vs.loading();
        await this.getTrips();
        loading.close();
        let el = document.getElementsByClassName('slideout')[0] || document.getElementsByClassName('explore-container')[0];
        el.onscroll = () => {
            let e = document.getElementsByClassName('slideout')[0] || document.getElementsByClassName('explore-container')[0];
            let bottomOfWindow = Math.abs(e.scrollTop - (e.scrollHeight - e.offsetHeight)) < 20;
            if (bottomOfWindow) {
                if (this.result != null && this.result.after != null) { // non ho finito le pagine e non sta caricando
                    this.after = this.result.after;
                }
            }
        };

    },

    watch: {
        after() {
            console.log('after changed')
            this.getTrips(false);
        }
    },

    methods: {
        newTripEvent() {
            GlobalEventEmitter.$emit('showDialog', NewTripDialog, this.newTripCallback) // chiedo il nome
        },
        newTripCallback(e) {
            if (e != null && e.length > 0) { // ha scelto un nome per il viaggio e ha premuto "prosegui" (non annulla)
                this.newTrip(e)
            }
        },

        async deleteTrip(trip) {
            const loading = this.$vs.loading();

            const response = await apiCall('DELETE', '/journeys/' + trip.id);
            // if status code is 200, show a success message 
            if (response.status == 200) {

                await this.getTrips();

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.cantCreateTrip'),
                    color: 'danger',
                    position: 'top-right'
                });
            }

            loading.close();

        },

        async newTrip(name) {
            // use apiCall to make a request to /trips
            const response = await apiCall('POST', '/journeys', {
                name
            });
            // if status code is 200, show a success message 
            if (response.status == 200) {
                // show a success message
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('trips.messages.tripCreated'),
                    color: 'success',
                    position: 'top-right'
                });
                // reload trips
                // start loading
                const loading = this.$vs.loading();
                await this.getTrips();
                loading.close();

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.cantCreateTrip'),
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async getTrips(reset = true) {

            // use apiCall to make a request to /trips
            const response = await apiCall('GET', '/journeys', {
                after: this.after
            });
            // if status code is 200, show a success message 
            if (response.status == 200) {
                if (reset) {
                    this.trips = [];
                }
                this.result = response.data;
                if (response.data.data != null) {
                    this.trips = this.trips.concat(response.data.data);
                } else {
                    this.trips = []
                }

            } else if (response.status == 402) {
                GlobalEventEmitter.$emit('showDialog', TrialExpiredDialog, () => {
                    this.$router.push({
                        name: 'Sottoscrizione'
                    })
                }) // chiedo la data in cui intende visitare il poi
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.unableToLoadTrips'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background-image: url('../../assets/images/trips.png');
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 140px;

}

.trips {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.trips-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

/* MOBILE */
@media (max-width: 600px) {
    .trips-container {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px !important;
        height: unset !important;
        width: 100% !important;

    }

}
</style>
